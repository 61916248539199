.App {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
}

h1 {
  margin: 0;
}

.app-content {
  flex: 1;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
}

.drop-area {
  border: 2px dashed #48c8ff;
  border-radius: 5px;
  background: #d0e9ff38;
  cursor: pointer;
  transition: 0.3s all;
}

.drop-area:hover {
  background: #a0e9ff38;
  transition: 0.3s all;
}

.drop-area input {
  /*display: none;*/
}

.drop-area__description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-content p {
  font-size: 24px;
  margin: 1em;
  text-align: center;
}

button {
  background: #48c8ff;
  color: #fff;
  padding: 20px 40px;
  font-size: 20px;
  border: 0;
  outline: 0;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background: #1daeed;
  transition: 0.2s all;
}

.result {
  flex-direction: column;
  margin: auto;
}

.result-image {
  width: 100%;
  max-width: 512px;
  margin-top: 2em;
}

.result-image img {
  width: 100%;
}

.warnings {
  height: 30%;
  display: flex;
  align-items: center;
}

.warnings svg {
  height: 130px;
}

.warnings-title {
  font-size: 18px;
}

.app-content-images {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: space-around;
}

.app-content-images-content {
  width: 50%;
  text-align: center;
  margin: auto 10px;
}

.app-content-images-content img {
  max-width: 600px;
  max-height: 500px;
}

@media (max-width: 1450px) {
  .app-content-images-content img {
    max-width: 450px;
    max-height: 400px;
  }
}
